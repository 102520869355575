header {
  background-color: $color-black;
  color: $color-white;
}

.header-menu {
  @media (max-width: 1024px) {
    background-color: $color-white;
    display: none;
    left: 0;
    padding: 30px;
    position: absolute;
    right: 0;
    top: 62px;
    z-index: 15;

    &.active { display: block }
  }
}

.btn-nav {
  align-items: center;
  background: none;
  border: 0;
  cursor: pointer;
  display: none;
  height: 30px;
  padding: 0;
  position: relative;

  @media (max-width: 1024px) { display: flex }

  &:active,
  &:focus {
    outline: none;
  }
}

.btn-nav span,
.btn-nav span:after,
.btn-nav span:before {
  background: $color-timber-green;
  height: 2px;
  width: 28px;
}

.btn-nav span {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}

.btn-nav span:after,
.btn-nav span:before {
  content: "";
  left: 0;
  position: absolute;
  transition: 500ms ease all;
}

.btn-nav span:after { bottom: -8px }

.btn-nav span:before { top: -8px }

.btn-nav.active span { background: none; }

.btn-nav.active span:before {
  top: 0;
  transform: rotate(-45deg);
}

.btn-nav.active span:after {
  bottom: 0;
  transform: rotate(45deg);
}
