body .rc-slider {
  height: 4px;
  padding: 0;
}

body .rc-slider-rail {
  background: $color-alabaster;
  border-radius: 2px;
}

body .rc-slider-track {
  background: $color-turquoise;
  border-radius: 2px;
}

body .rc-slider-handle {
  background: $color-white;
  border: 2px solid $color-turquoise;
  height: 10px;
  margin-top: -3px;
  width: 10px;
}

body .rc-slider-handle:active {
  box-shadow: none;
}

body .rc-slider-handle:focus { box-shadow: none }
