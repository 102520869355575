.document-box {
  align-items: center;
  background-color: $color-white;
  border: 1px solid $color-timber-green-o-16;
  border-radius: 8px;
  box-shadow: 0px 16px 32px rgba(0,0,0,.08);
  display: flex;
  margin-bottom: 40px;
  min-height: 80px;
  padding-left: 2rem;
  padding-right: 2rem;
}