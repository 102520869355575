.data-capture-sidebar {
  background-color: $color-timber-green;
  bottom: 0;
  font-size: 15px;
  left: 0;
  max-width: 340px;
  overflow-y: auto;
  position: fixed;
  top: 62px;
  width: 100%;
  z-index: 10;

  ::-webkit-scrollbar, &::-webkit-scrollbar             { width: 5px }
  ::-webkit-scrollbar-track, &::-webkit-scrollbar-track { background: $color-timber-green; border-radius: 20px; }
  ::-webkit-scrollbar-thumb, &::-webkit-scrollbar-thumb { background: $color-white-o-32; border-radius: 20px; }

  @media (max-width: 640px) { display: none }
}

.submenu-container {
  max-height: 265px;
  overflow-y: auto;
  padding: 1rem 1.5rem;
}

.data-capture-sidebar-link {
  align-items: center;
  border-bottom: 1px solid $color-timber-green-o-8;
  border-radius: 8px;
  color: $color-white;
  display: flex;
  letter-spacing: -.5px;
  padding: 12px 1rem;
  position: relative;
  width: 100%;

  &.active { background-color: $color-white-o-16 }

  &--submenu {
    color: $color-white-o-50;
    font-size: 13px;
    padding-bottom: 8px;
    padding-top: 8px;
  }

  &--submenu img { opacity: .5 }
}

.data-capture-sidebar-icon {
  align-items: center;
  border-radius: 50%;
  color: $color-white;
  display: flex;
  font-size: 11px;
  height: 18px;
  justify-content: center;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;

  &--error {
    background-color: $color-punch;
    font-weight: 600;
  }

  &--success { background-color: $color-turquoise }

  &--applicant {
    margin-left: 1rem;
    position: static;
    transform: none;
  }
}

.data-capture-sidebar-link--submenu .data-capture-sidebar-icon { right: 12px }