.bg-alabaster         { background-color: $color-alabaster }
.bg-black             { background-color: $color-black }
.bg-black-o-4         { background-color: $color-black-o-4 }
.bg-black-o-8         { background-color: $color-black-o-8 }
.bg-punch             { background-color: $color-punch }
.bg-timber-green      { background-color: $color-timber-green }
.bg-timber-green-o-8  { background-color: $color-timber-green-o-8 }
.bg-timber-green-o-16 { background-color: $color-timber-green-o-16 }
.bg-timber-green-o-40 { background-color: $color-timber-green-o-40 }
.bg-turquoise         { background-color: $color-turquoise }
.bg-white             { background-color: $color-white }

.border-transparent       { border-color: transparent }
.border-timber-green      { border-color: $color-timber-green }
.border-timber-green-o-8  { border-color: $color-timber-green-o-8 }
.border-timber-green-o-16 { border-color: $color-timber-green-o-16 }
.border-tulip-tree        { border-color: $color-tulip-tree }
.border-turquoise         { border-color: $color-turquoise }
.border-white             { border-color: $color-white }
.border-white-o-15        { border-color: $color-white-o-15 }

.text-black             { color: $color-black }
.text-bright-sun        { color: $color-bright-sun }
.text-punch             { color: $color-punch }
.text-timber-green      { color: $color-timber-green }
.text-timber-green-o-16 { color: $color-timber-green-o-16 }
.text-timber-green-o-40 { color: $color-timber-green-o-40 }
.text-timber-green-o-60 { color: $color-timber-green-o-60 }
.text-turquoise         { color: $color-turquoise }
.text-white             { color: $color-white }

.transition-all          { transition: 300ms ease-out all }
.transition-bg-color     { transition: 300ms ease-out background-color }
.transition-border-color { transition: 300ms ease-out border-color }
.transition-color        { transition: 300ms ease-out color }

.placeholder-red::-moz-placeholder          { color: red; opacity:1 }
.placeholder-red:-ms-input-placeholder      { color: red; opacity:1 }
.placeholder-red::-webkit-input-placeholder { color: red; opacity:1 }
