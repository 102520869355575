.btn {
  border-radius: 32px;
  cursor: pointer;
  display: inline-block;
  padding: 13px 2rem;

  @media (max-width: 1536px) {
    padding: 13px;
  }

  &.disabled,
  &:disabled {
    cursor: default;
    opacity: .5;
    pointer-events: none;
  }
}

.btn--small {
  font-size: 13px;
  padding: 3px 1rem;

  @media (max-width: 640px) {
    font-size: 11px
  }
}

.btn--primary {
  background-color: $color-turquoise;
  color: $color-white;
}

.btn--primary.btn--small { border: 1px solid transparent }

.btn--secondary {
  background-color: $color-timber-green;
  color: $color-white;
}

.btn--white {
  background-color: $color-white;
  color: $color-turquoise;
}

.btn--noticies {
  background-color: $color-white;
  border-radius: 8px;
  color: $color-timber-green;
}

.btn--outlined-primary {
  background: $color-timber-green-o-8;
  border: 1px solid $color-timber-green-o-16;
  padding: 11px 22px;

  &.btn--small {
    padding: 3px 1rem;
  }
}

.btn-group-container {
  background: $color-alabaster;
  border: 1px solid $color-timber-green-o-16;
  border-radius: 47px;
  height: 56px;
  padding: 8px;

  &.error {
    background: $color-punch-o-8;
    border-color: $color-punch-o-16;
  }
}

.btn--group {
  line-height: 40px;
  padding: 0;

  &.btn--outlined-primary {
    line-height: 38px;
  }
}

.btn-group--small {
  .btn-group-container {
    background: none;
    border: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .btn--group {
    font-size: 13px;
    line-height: 32px;
    padding: 0 8px;

    &.btn--outlined-primary {
      line-height: 30px;
    }
  }
}
