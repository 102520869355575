@media (max-width: 1536px) {
  .table-outer-container { overflow-x: auto }
  .table-container { min-width: 1170px }
}

@media (max-width: 1800px) {
  .table-outer-container--application-dashboard { overflow-x: auto }
  .table-container--application-dashboard {
    max-height: 550px;
    min-width: 1400px;
  }
}

.table {
  border-radius: 8px;
  width: 100%;

  &.table--border {
    border: 1px solid $color-timber-green-o-8;
    border-collapse: separate;
  }

  tbody {
    &.table--border {
      border: 1px solid $color-timber-green-o-8;
      border-collapse: separate;
      tr {
        td {
          border: 1px solid $color-timber-green-o-8;
          border-collapse: separate;
        }
      }
    }
  }

  th {
    background-color: $color-timber-green;
    color: $color-white;
    padding: 10px 2rem;
    text-align: left;

    &.text-center {
      text-align: center;
    }
  }

  th:first-child { border-radius: 8px 0 0 8px }
  th:last-child { border-radius: 0 8px 8px 0 }

  tbody td {
    background-color: $color-white;
    border-bottom: 1px solid $color-timber-green-o-8;
    padding: 1rem 2rem;

    @media (max-width: 1536px) {
      padding: 1rem;
    }
  }

  &.table--borrower { box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.08) }

  &.table--borrower tbody tr:last-child td:not(:last-child) {
    border-right: 1px solid $color-timber-green-o-8;
  }
}

@media (min-resolution: 120dpi) {
  .table tbody td {
    border-bottom-width: 0.75px;
  }
}

@media (min-resolution: 144dpi) {
  .table tbody td {
    border-bottom-width: 0.666px;
  }
}

@media (min-resolution: 192dpi) {
  .table tbody td {
    border-bottom-width: 0.5px;
  }
}