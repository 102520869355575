.tooltip {
  background: #17282E;
  border-radius: 32px;
  border: 1px solid transparent;
  bottom: 60px;
  color: $color-white;
  display: inline-block;
  font-size: 12px;
  line-height: 21px;
  max-width: 400px;
  opacity: 0;
  padding: 13px 32px;
  position: absolute;
  right: -33px;
  transition: all 0.3s ease-out;
  visibility: hidden;
  width: 3000%;
  z-index: 10;

  &.active {
    bottom: 33px;
    opacity: 1;
    visibility: visible;
  }

  &:after {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top-color: #17282E;
    border-top-style: solid;
    border-top-width: 12px;
    content: "";
    height: 0;
    position: absolute;
    top: 100%;
    right: 30px;
    width: 0;
  }
}

.tooltip a {
  color: $color-white;
  text-decoration: underline;
}