.applicant-image-container {
  display: flex;
  position: relative;

  &:after {
    background-color: $color-timber-green-o-16;
    content: '';
    height: 2px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }
}

.applicant-image {
  align-items: center;
  border: 2px solid $color-timber-green-o-16;
  border-radius: 50%;
  display: flex;
  height: 30px;
  justify-content: center;
  position: relative;
  width: 30px;
  z-index: 1;
}

.applicant-image:not(:last-child) { margin-right: 16px }