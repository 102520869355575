.calculator-table-title {
  font-weight: 700;

  @media (max-width: 640px) {
    font-size: 13px;
  }
}

.calculator-table-title--large {
  color: $color-turquoise;
  font-size: 24px;
}

.calculator-table-text {
  font-size: 13px;

  @media (max-width: 640px) {
    font-size: 10px
  }
}