.custom-checkbox ~ label:before {
  color: $color-timber-green;
  content: "\f0c8";
  font-family: "Font Awesome 5 Pro";
  left: 2px;
  position: absolute;
  top: 0;
}

.custom-checkbox:checked ~ label:before { color: $color-turquoise }

.custom-checkbox:checked ~ label:before { content: "\f14a" }

.custom-checkbox ~ label {
  padding-left: 30px;
  position: relative;
}