body .PhoneInput {
  background: $color-alabaster;
  border: 1px solid $color-timber-green-o-16;
  border-radius: 47px;
  color: $color-timber-green;
  height: 56px;
  padding: 0 32px;
  width: 100%;
}

body .error .PhoneInput {
  background: $color-punch-o-8;
  border-color: $color-punch-o-16;
}

body .PhoneInputCountry {
  border-right: 1px solid $color-timber-green-o-16;
  margin-right: 16px;
  padding-right: 16px;
}

body .PhoneInputInput {
  background: none;
  border: none;
  height: 56px;
}

body .PhoneInputCountrySelectArrow {
  border: 0;
  opacity: 1;
  position: relative;
  transform: none;
}

body .PhoneInputCountrySelectArrow:after {
  color: $color-timber-green;
  content: "\f0dd";
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  margin-top: -4px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}