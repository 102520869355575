body .react-select__control {
  background: $color-alabaster;
  border: 1px solid $color-timber-green-o-16 !important;
  border-radius: 47px;
  box-shadow: none;
  color: $color-timber-green;
  min-height: 56px;
  position: relative;
  text-align: left;
  transition: none;
  width: 100%;
}

body .react-select-white-bg .react-select__control { background-color: $color-white }

body .error .react-select__control {
  background: $color-punch-o-8;
  border-color: $color-punch-o-16;
}

body .react-select__control.react-select__control--menu-is-open {
  border-radius: 28px 28px 0 0;
}

body .react-select__placeholder { color: $color-timber-green; }

body .react-select__indicator-separator { display: none }

body .react-select__value-container {
  padding-left: 32px;
  padding-right: 32px;
}

body .react-select__single-value {
  color: $color-timber-green;
  left: 2rem;
  margin: 0;
  right: 60px;
}

body .react-select__indicator svg { display: none }

body .react-select__indicator {
  border: none;
  height: 10px;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 32px;
  top: 50%;
  transform: translateY(-50%);
  width: 17px;
}

body .react-select__indicator:after {
  color: $color-timber-green;
  content: "\f078";
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

body .react-select__menu {
  background: $color-alabaster;
  border: 1px solid $color-timber-green-o-16;
  border-top: 0;
  border-radius: 0 0 28px 28px;
  box-shadow: 0px 16px 32px rgba(23, 40, 46, 0.08);
  margin: -17px 0 0;
  padding: 0 32px 17px;
  z-index: 5;
}

body .react-select-white-bg .react-select__menu { background-color: $color-white }

body .react-select__menu-list {
  text-align: left;
}

body .react-select__option {
  color: $color-black;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  padding: 16px 0;
}

body .react-select__option:not(:last-child) {
  border-bottom: 1px solid $color-timber-green-o-16;
}

body .react-select__option--is-selected,
body .react-select__option--is-focused {
  background: none;
  color: $color-turquoise;
}

body .react-select__value-container--is-multi {
  max-height: 92px;
  overflow-y: auto;
}
