.bg-registration {
  background-image: url('~images/background/registration.jpg');
  background-position: right top;
  background-repeat: no-repeat;
  background-size: contain;

  @media (max-width: 1024px) { background: none }
}

.bg-forgot-password {
  background-image: url('~images/background/forgot-password.jpg');
  background-position: right top;
  background-repeat: no-repeat;
  background-size: contain;

  @media (max-width: 1024px) { background: none }
}

.bg-2fa {
  background-image: url('~images/background/2fa.jpg');
  background-position: right top;
  background-repeat: no-repeat;
  background-size: contain;

  @media (max-width: 1024px) { background: none }
}