.form-title {
  color: $color-timber-green-o-16;
  font-size: 24px;
  font-weight: 700;
}

.form-item-container {
  margin-bottom: 40px;

  @media (max-width: 1450px) { margin-bottom: 20px }
}

.label {
  color: $color-timber-green;
  display: block;
  font-size: 14px;
}

.input,
.input-2fa,
.flatpickr-input,
.textarea {
  background: $color-alabaster;
  border: 1px solid $color-timber-green-o-16;
  color: $color-timber-green;

  &.error {
    background: $color-punch-o-8;
    border-color: $color-punch-o-16;
  }
}

.input {
  border-radius: 47px;
  height: 56px;
  padding: 0 32px;
  width: 100%;
}

.input-2fa {
  border-radius: 4px;
  height: 32px;
  margin: 0 8px;
  text-align: center;
  width: 24px;
}

.textarea {
  border-radius: 28px;
  padding: 22px 32px;
  resize: none;
  width: 100%;
}

#error_explanation {
  background-color: $color-punch-o-8;
  border: 1px solid $color-punch-o-16;
  border-radius: 8px;
  font-size: 14px;
  margin-bottom: 40px;
  padding: 1rem;
}

.field_with_errors input,
.input.parsley-error {
  background-color: $color-punch-o-8;
  border-color: $color-punch-o-16;
}

.parsley-errors-list {
  color: $color-punch;
  font-size: 10px;
  margin-top: 4px;
  padding: 0 2rem;
}

.optional-text {
  color: $color-timber-green-o-40;
  font-size: 12px;
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
}

.error-text {
  color: $color-punch;
  font-size: 10px;
  margin-bottom: .5rem;
  margin-top: 4px;
  padding-left: 2rem;
  padding-right: 2rem;
}

.success-text {
  color: $color-turquoise;
  font-size: 10px;
  margin-bottom: .5rem;
  margin-top: 4px;
  padding-left: 2rem;
  padding-right: 2rem;
}

.calculator-mortgage-container {
  position: relative;

  .input { padding-right: 120px }
}

.calculator-mortgage-percent {
  border-left: 1px solid $color-timber-green-o-16;
  line-height: 56px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 29px;
  width: 100px;
}