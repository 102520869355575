.switch {
  display: inline-block;
  height: 16px;
  position: relative;
  width: 26px;
}

.switch input {
  height: 0;
  opacity: 0;
  width: 0;
}

.slider {
  background-color: $color-timber-green;
  border-radius: 20px;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: .4s;
}

.slider:before {
  background-color: white;
  border-radius: 50%;
  bottom: 3px;
  content: "";
  height: 10px;
  left: 4px;
  position: absolute;
  transition: .4s;
  width: 10px;
}

input:checked + .slider { background-color: $color-turquoise }
input:checked + .slider:before { transform: translateX(8px) }
