body .rmsc .dropdown-container {
  background: $color-alabaster;
  border: 1px solid $color-timber-green-o-16 !important;
  border-radius: 47px;
  box-shadow: none !important;
  color: $color-timber-green;
  line-height: 56px;
  min-height: 56px;
  position: relative;
  text-align: left;
  transition: none;
  width: 100%;
}

body .error .rmsc .dropdown-container {
  background: $color-punch-o-8;
  border-color: $color-punch-o-16 !important;
}

body .rmsc .dropdown-heading {
  height: 56px;
  padding-left: 32px;
  padding-right: 32px;
}

body .rmsc .gray { color: $color-timber-green }

body .rmsc .dropdown-content {
  margin: -27px 0 0;
  z-index: 10;
}

body .rmsc .dropdown-content .panel-content {
  background: none;
  border-radius: 0 0 28px 28px;
  box-shadow: none;
  line-height: normal;
}

body .rmsc .select-panel {
  background: $color-alabaster;
  border: 1px solid $color-timber-green-o-16;
  border-top: 0;
  border-radius: 0 0 28px 28px;
  box-shadow: 0px 16px 32px rgba(23, 40, 46, 0.08);
  padding: 20px 32px 17px;
  z-index: 5;
}