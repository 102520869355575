body .slick-dots li { margin: 0 2px }

body .slick-dots li button:before {
  color: $color-timber-green-o-16;
  font-size: 12px;
  opacity: 1;
}

body .slick-dots li.slick-active button:before { color: $color-turquoise }

body .slick-flex .slick-track,
body .slick-flex .slick-item-wrapper,
body .slick-flex .slick-slide {
  display: flex !important;
}

body .slick-flex .slick-slide {
  float: none;
  height: auto;
}

body .slick-flex .slick-slide > div { display: flex }

body .slick-next:before,
body .slick-prev:before {
  color: $color-timber-green;
  font-size: 32px;
  font-family: "Font Awesome 5 Pro";
}

body .slick-next:before { content: "\f054" }
body .slick-prev:before { content: "\f053" }

.slick--about-us {
  position: relative;

  .slick-next,
  .slick-prev {
    height: 32px;
    width: 16px;
    z-index: 10;

    &:before { opacity: 1 }
  }

  & .slick-next { right: 13% }
  & .slick-prev { left: 13% }

  @media (max-width: 1024px) {
    & .slick-next { right: 0 }
    & .slick-prev { left: 0 }
  }
}

.slick--about-us .slick-slider {
  &:after,
  &:before {
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
    width: 30%;
    z-index: 5;
  }

  @media (max-width: 1024px) {
    &:after,
    &:before {
      display: none;
    }
    }

  &:after {
    background: linear-gradient(to right, $color-alabaster 50%, rgba(248, 248, 248, 0));
    left: 0;
  }

  &:before {
    background: linear-gradient(to left, $color-alabaster 50%, rgba(248, 248, 248, 0));
    right: 0;
  }
}