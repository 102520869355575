.progress-indicator-container {
  display: flex;
  justify-content: center;

  &.disable-navigation button {
    cursor: default !important;
  }
}

.progress-indicator-line {
  position: relative;

  &:after {
    background-color: $color-timber-green-o-16;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.progress-circle {
  background-color: $color-white;
  border: 2px solid $color-timber-green-o-16;
  border-radius: 50%;
  color: $color-timber-green-o-16;
  font-size: 12px;
  font-weight: 700;
  height: 32px;
  position: relative;
  width: 32px;
  z-index: 1;

  &:not(:last-child) { margin-right: 64px }

  &.active {
    border-color: $color-timber-green;
    color: $color-timber-green;
  }

  &.valid {
    background-color: #2ED3AE;
    color: white;
  }

  &.invalid {
    background-color: #DB322A;
    color: white;
  }
}
