.landing-feature-column {
  display: flex;
  margin-bottom: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  width: 100%;

  @media (min-width: 1024px) {
    margin-bottom: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 25%;
  }
}

.landing-feature-column--mortgage {
  @media (min-width: 1024px) {
    width: 50%;
  }
}

.landing-feature-box {
  background-color: $color-white;
  border-bottom: 8px solid $color-turquoise;
  border-radius: 0.5rem;
  box-shadow: 0px 16px 32px rgba(0,0,0,.08);
  padding: 2rem;
  text-align: center;
  width: 100%;

  @media (min-width: 1024px) { text-align: left }
}

.landing-feature-box--mortage {
  border-bottom: 0;
  border-left: 8px solid $color-turquoise;
  margin-bottom: 32px;
}

.landing-feature-icon-container { min-height: 100px }

.landing-feature-icon {
  margin: 0 auto 1rem;

  @media (min-width: 1024px) { margin: 0 0 1rem; }
}

.landing-feature-title {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
  margin-bottom: 1rem;
}

.landing-hero {
  background-color: $color-alabaster;
  padding: 110px 0 20px;
}

.landing-link {
  color: $color-turquoise;
  text-decoration: underline;
}
