*:focus { outline: none }

html { min-height: 100%}

body {
  -webkit-font-smoothing: antialiased;
  color: $color-timber-green;
  font-family: 'Lexend', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  padding-top: 62px;

  &.body-devise {
    display: flex;
    min-height: 100vh;
    padding-top: 62px;
  }
}

h1, h2, h3, h4, h5, h6, input, textarea, select, button {
  font-family: 'Lexend', sans-serif;
}

a {
  color: $color-black;
  text-decoration: none;
}

.container { margin-left: auto; margin-right: auto; }
@media (max-width: 1024px) {
  .container { padding-left: 2rem; padding-right: 2rem; }
}

.container--main { max-width: $grid-width }

.container--application-dashboard {
  max-width: 1780px;
  padding-left: 360px;
  padding-right: 20px;
}

.container--document,
.container--form {
  max-width: 1093px;
  padding-left: 310px;

  @media (max-width: 640px) { padding-left: 1rem; padding-right: 1rem; }
}

.container--document {
  max-width: 1480px;
  padding-left: 400px;
  padding-right: 50px;
  @media (max-width: 1536px) { padding-left: 350px; padding-right: 20px; }
  @media (max-width: 640px) { padding-left: 0 }
}

.container--devise {
  max-width: 343px;

  @media (max-width: 1024px) {
    max-width: none;
    padding: 2rem;
  }
}

.container-padding-large {
  padding-bottom: 9rem;
  padding-top: 9rem;

  @media (max-width: 640px) {
    padding-bottom: 60px;
    padding-top: 60px;
  }
}

.container-padding-small {
  padding-bottom: 6rem;
  padding-top: 6rem;

  @media (max-width: 640px) {
    padding-bottom: 50px;
    padding-top: 50px;
  }
}

.border-dashed-white {
  position: relative;

  &:after {
    border-bottom: 2px solid $color-white;
    border-top: 2px solid $color-white;
    bottom: -2px;
    content: "";
    left: 50%;
    position: absolute;
    top: -2px;
    transform: translateX(-50%);
    width: 200px;
  }

  &:before {
    border-left: 2px solid $color-white;
    border-right: 2px solid $color-white;
    content: "";
    height: 50px;
    left: -2px;
    position: absolute;
    right: -2px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.title {
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
}

@media (max-width: 640px) {
  .title {
    font-size: 32px;
    line-height: 40px;
  }
}

.sub-title {
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
}

@media (max-width: 640px) {
  .sub-title {
    font-size: 24px;
    line-height: 30px;
  }
}

.iframe-container {
  padding-bottom: 56.25%;
  position: relative;

  iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.anchor {
  position: absolute;
  top: -62px;
}