//generate colors here: http://chir.ag/projects/name-that-color/
$color-alabaster: #F8F8F8;
$color-black: #000;
$color-black-o-4: rgba(0,0,0,.04);
$color-black-o-8: rgba(0,0,0,.08);
$color-bright-sun: #FFC845;
$color-punch: #DB322A;
$color-punch-o-8: rgba(219,50,42,0.08);
$color-punch-o-16: rgba(219,50,42,0.16);
$color-timber-green: #17282E;
$color-timber-green-o-8: rgba(23,40,46,.08);
$color-timber-green-o-16: rgba(23,40,46,.16);
$color-timber-green-o-40: rgba(23,40,46,.4);
$color-timber-green-o-60: rgba(23,40,46,.6);
$color-tulip-tree: #E5B43D;
$color-turquoise: #2ED3AE;
$color-turquoise-o-8: rgba(46,211,174,.08);
$color-turquoise-o-16: rgba(46,211,174,.16);
$color-white: #fff;
$color-white-o-15: rgba(255,255,255,.15);
$color-white-o-16: rgba(255,255,255,.16);
$color-white-o-32: rgba(255,255,255,.32);
$color-white-o-50: rgba(255,255,255,.5);

$grid-width: 1170px;
