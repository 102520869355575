.box-shadow        { box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.08) }
.box-shadow-header { box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08) }

.circle { border-radius: 50% }

.fs-9  { font-size: 9px }
.fs-10 { font-size: 10px }
.fs-13 { font-size: 13px }

.last-item-no-margin:last-child { margin: 0 }

.mb-40 { margin-bottom: 40px }

.p-40 { padding: 40px }

.pb-30 { padding-bottom: 30px }

.pt-30 { padding-top: 30px }

.px-40 { padding-left: 40px; padding-right: 40px; }
.px-50 { padding-left: 50px; padding-right: 50px; }

.py-0 { padding-bottom: 0 !important; padding-top: 0 !important; }
.py-20 { padding-bottom: 20px; padding-top: 20px; }

.left-32 { left: 32px }

.right-32 { right: 32px }

.w-1\/7 {
  width: 14.2857143%;
}

.link, .link:hover, .link:active, .link:visited {
  color: #1a0dab !important;
}

.overflow-hidden {
  overflow: hidden;
}

.xs-hidden {
  @media (min-width: 450px) {
    display: none;
  }
}
