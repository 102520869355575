.flash-message {
  border-radius: 8px;
  font-size: 14px;
  margin-bottom: 40px;
  padding: 1rem;

  &.error {
    background-color: $color-punch-o-8;
    border: 1px solid $color-punch-o-16;
  }

  &.success {
    background-color: $color-turquoise-o-8;
    border: 1px solid $color-turquoise-o-16;
  }
}